import { Router, Route, Switch } from "react-router-dom";
import React, { Suspense, Fragment } from "react";
import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";
import PrivateRoutes from "../../utils/PrivateRoute";
import History from "../../utils/History";
import Login from "../../Pages/Login";
import { ItsLoginUser } from "Pages/Login/ItsLoginUser";
import NotAuthorised from "Pages/NotAuthorised/index"

const AppMain = () => {

  const suspenseFallback = (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse" />
        </div>
        <h6 className="mt-3">
          Please wait while we are loading all the details.
          <small>
            The system is loading all the relevant data from the databases for you.
          </small>
        </h6>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Suspense fallback={suspenseFallback}>
        <Router history={History}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/its-login" component={ItsLoginUser} />
            <Route path="/not-authorised" component={NotAuthorised} />
            <Route path="/" component={PrivateRoutes} />
          </Switch>
        </Router>
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
