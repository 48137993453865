export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
// return the user data from the session storage
export const getUser = () => {
  let userStr = {};
  userStr.its_id = getCookie("user_its")?.split(",")[0];
  userStr.role = getCookie("role");
  if (userStr) return userStr;
  else return null;
};

export const getAmilSaheb = () => {
  let Amil = localStorage.getItem("amil_saheb");
  if (Amil) return JSON.parse(Amil);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("fa-token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("fa-token");
  localStorage.removeItem("user");
  localStorage.removeItem("active-co-cardtab");
  localStorage.removeItem("active-trustee-cardtab");
  localStorage.removeItem("username");

  // Get all cookies
  const cookies = document?.cookie?.split(";");

  // Loop through each cookie and clear it
  cookies?.forEach((cookie) => {
    const cookieName = cookie?.split("=")[0]?.trim();
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });

  const keys = [
    "araz.application_id",
    "marhala",
    "track_id",
    "user_tanzeem_id",
    "token",
  ];
  for (const key of keys) {
    localStorage.removeItem(key);
  }
  // window.location.replace("https://www.talabulilm.com/logout.php");
};
export const isAdmin = () => {
  return localStorage.getItem("isAdmin");
};
export const getAPIUrl = () => {
  // return 'http://localhost/faapi/';
  return "https://reacttest.talabulilm.com/react-api/api/";
  // return 'https://talabulilm.com/react-api/api/';
};

const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = process.env.REACT_APP_API_URL;

export const getSLIM4APIUrl = () => `${baseUrl}${apiUrl}`;
// export const getSLIM4APIUrl = () => "https://www.talabulilm.com/api2022/fa/";
console.log(baseUrl, "commonBaseUrl");
console.log(apiUrl, "commonApiUrl");

export const getUrl = () => {
  // return 'http://localhost/react/fa-araz-react/fa-talabulilm-react-api/api/';
  return "https://reacttest.talabulilm.com/react-api/api/";
  // return 'https://talabulilm.com/react-api/api/';
};
export const getArazAPIUrl = () => {
  // return 'http://localhost/react/fa-araz-react/fa-talabulilm-react-api/api/';
  return "https://talabulilm.com/araiz/api/";
};
export const getUploadUrl = () => {
  // return 'http://localhost/react/fa-talabulilm-react-app/fa-talabulilm-react-api/upload/';
  return "https://reacttest.talabulilm.com/upload/";
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem("fa-token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

// set the token and user from the session storage
export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};
// set the token and user from the session storage
export const setLocalStorageAsJson = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};
// set the token and user from the session storage
export const getLocalStorage = (name) => {
  return localStorage.getItem(name) || null;
};
// set the token and user from the session storage
export const getLocalStorageAsJson = (name) => {
  const getItem = localStorage.getItem(name);
  if (getItem) return JSON.parse(getItem);
  else return null;
};
// set the token and user from the session storage
export const removeLocalStorageAsJson = (name) => {
  localStorage.removeItem(name);
};

export const getAuthToken = () => "Basic " + localStorage.getItem("fa-token");

var a = [
  "",
  "One ",
  "Two ",
  "Three ",
  "Four ",
  "Five ",
  "Six ",
  "Seven ",
  "Eight ",
  "Nine ",
  "Ten ",
  "Eleven ",
  "Twelve ",
  "Thirteen ",
  "Fourteen ",
  "Fifteen ",
  "Sixteen ",
  "Seventeen ",
  "Eighteen ",
  "Nineteen ",
];
var b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

export const inWords = (num) => {
  if ((num = num > 0 && num?.toString()).length > 9) return "";
  let n =
    num > 0 &&
    ("000000000" + num?.split(".")[0])
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "Only "
      : "";
  return str;
};

export const IndianRupeeFormat = (x) => {
  x = parseInt(x).toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};
