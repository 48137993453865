import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import { ContextProvider } from "./Context";
import history from "./utils/History";
import { CookiesProvider } from "react-cookie";
import ViewListContextProvider from "ViewListContext";
import DocContextProvider from "Pages/Accounts/Documents/DocContext";
import FileUploadContextProvider from "Pages/Accounts/FileUpload/FileUploadContext";
const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <ContextProvider history={history}>
            <ViewListContextProvider>
              <FileUploadContextProvider>
                <DocContextProvider>
                  <BrowserRouter>
                    <Component />
                  </BrowserRouter>
                </DocContextProvider>
              </FileUploadContextProvider>
            </ViewListContextProvider>
          </ContextProvider>
        </Suspense>
      </Provider>
    </CookiesProvider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
