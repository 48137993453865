import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getSLIM4APIUrl } from "utils/Common";

const FileUploadContext = createContext(null);

const FileUploadContextProvider = ({ children }) => {
  const [viewFileList, setViewFileList] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [application_id, setApplication_id] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [applicationData, setApplicationData] = useState(null);

  const getFileList = async () => {
    const res = await axios.get(
      `${getSLIM4APIUrl()}accounts/getAccountantDocumentsList/${application_id}`
    );

    setFileList(res.data);
  };

  const setAllData = async () => {
    const response = await axios.get(
      `${getSLIM4APIUrl()}user/applicationDetails/${application_id}`
    );
    setApplicantData(response.data.ApplicantDetails);
    setApplicationData(response.data);
  };

  useEffect(() => {
    application_id && getFileList();
    application_id && setAllData();
  }, [application_id]);

  return (
    <FileUploadContext.Provider
      value={{
        viewFileList,
        fileList,
        application_id,
        applicantData,
        applicationData,
        setViewFileList,
        setApplication_id,
        getFileList,
        setAllData,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadContextProvider;

export const useFileUploadContext = () => useContext(FileUploadContext);
