import React, { createContext, useState, useContext } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const DocContext = createContext(null);

// Configuration for different document types
const DOC_CONFIGS = {
  istirshadFormat: {
    filename: "IstirshadFormat.pdf",
    pageSize: "a4",
    margin: { top: 5, right: 10, bottom: 5, left: 10 },
    scale: 2,
  },
  default: {
    filename: "document.pdf",
    pageSize: "a4",
    margin: { top: 10, right: 10, bottom: 10, left: 10 },
    scale: 2,
  },
};

export const DocContextProvider = ({ children }) => {
  const [ref, setRef] = useState(null);

  const downloadPDF = async (configKey = "default") => {
    try {
      if (!ref?.current) {
        throw new Error("Element reference is not valid");
      }

      const element = ref.current;
      const config = DOC_CONFIGS[configKey] || DOC_CONFIGS.default;
      const { filename, pageSize, margin, scale } = config;

      // Get page dimensions
      const pageDimensions = getPageDimensions(pageSize);
      const { width: pageWidth, height: pageHeight } = pageDimensions;

      // Create PDF document
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: pageSize,
      });

      // Calculate content dimensions
      const contentHeight = element.scrollHeight;
      const availablePageHeight = pageHeight - margin.top - margin.bottom;
      const totalPages = Math.ceil(contentHeight / availablePageHeight);

      // Process each page
      let processedHeight = 0;

      for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        if (pageNum > 0) {
          pdf.addPage();
        }

        const remainingHeight = contentHeight - processedHeight;
        const currentPageHeight = Math.min(
          availablePageHeight,
          remainingHeight
        );

        // Capture current section
        const canvas = await captureSection(element, {
          top: processedHeight,
          height: currentPageHeight,
          scale,
          margin,
        });

        // Add to PDF
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const availableWidth = pageWidth - margin.left - margin.right;
        const imgWidth = availableWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(
          imgData,
          "JPEG",
          margin.left,
          margin.top,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        processedHeight += currentPageHeight;
      }

      // Handle special elements
      await handleSpecialElements(pdf, element, {
        pageHeight: availablePageHeight,
        margin,
        pageWidth,
      });

      // Save the PDF
      pdf.save(filename);
      return true;
    } catch (error) {
      console.error("PDF generation failed:", error);
      return false;
    }
  };

  // Helper functions
  const getPageDimensions = (pageSize) => {
    const dimensions = {
      a4: { width: 595.28, height: 875.89 },
      letter: { width: 612, height: 792 },
    };
    return dimensions[pageSize] || dimensions.a4;
  };

  const captureSection = async (element, { top, height, scale, margin }) => {
    return html2canvas(element, {
      scale,
      useCORS: true,
      allowTaint: true,
      scrollY: -window.scrollY,
      windowHeight: element.scrollHeight,
      y: top,
      height,
      onclone: (document) => {
        const clonedElement = document.querySelector('[ref="pdfRef"]');
        if (clonedElement) {
          clonedElement.style.transform = "none";
        }
      },
    });
  };

  const handleSpecialElements = async (
    pdf,
    element,
    { pageHeight, margin, pageWidth }
  ) => {
    // Handle tables
    const tables = element.querySelectorAll("table");
    tables.forEach((table) => {
      const tableRect = table.getBoundingClientRect();
      const tableTop = tableRect.top + window.scrollY;
      const tableBottom = tableRect.bottom + window.scrollY;
      const tablePage = Math.floor(tableTop / pageHeight);
      const tableEndPage = Math.floor(tableBottom / pageHeight);

      if (tablePage !== tableEndPage) {
        const yOffset = (tablePage + 1) * pageHeight - tableTop + margin.top;
        pdf.setPage(tablePage + 1);
        pdf.addImage(
          table.toDataURL("image/jpeg", 1.0),
          "JPEG",
          margin.left,
          yOffset,
          pageWidth - margin.left - margin.right,
          tableRect.height,
          undefined,
          "FAST"
        );
      }
    });

    // Handle financial sections
    const financialSections = element.querySelectorAll(".financial-section");
    let lastSectionBottom = 0;

    financialSections.forEach((section) => {
      const sectionRect = section.getBoundingClientRect();
      const sectionBottom = sectionRect.bottom + window.scrollY;
      lastSectionBottom = Math.max(lastSectionBottom, sectionBottom);
    });

    return lastSectionBottom;
  };

  return (
    <DocContext.Provider value={{ ref, setRef, downloadPDF }}>
      {children}
    </DocContext.Provider>
  );
};

export const useDocContext = () => {
  const context = useContext(DocContext);
  if (!context) {
    throw new Error("useDocContext must be used within a DocContextProvider");
  }
  return context;
};

export default DocContextProvider;
