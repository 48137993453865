import React, { createContext, useContext, useEffect, useState } from "react";

const ViewListContext = createContext(null);

const ViewListContextProvider = ({ children }) => {
  const [showTable, setShowTable] = useState(
    localStorage.getItem("showTable")
      ? JSON.parse(localStorage.getItem("showTable"))
      : false
  );

  useEffect(() => {
    localStorage.setItem("showTable", showTable);
  }, [showTable]);

  return (
    <ViewListContext.Provider value={{ showTable, setShowTable }}>
      {children}
    </ViewListContext.Provider>
  );
};

export default ViewListContextProvider;

export const useViewList = () => useContext(ViewListContext);
