import React, { Component } from "react";
import { Col, Row } from "reactstrap";

class NotAuthorised extends Component {
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: 350, margin: "10px 20px" }}>
                    <Row>
                        <Col>
                        <h3>Not Available</h3>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default NotAuthorised;