// component's config object.
const components = {
  admin: {
    component: "Admin",
    url: "admin/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  admin_id: {
    component: "Admin",
    url: "admin/dashboard/:id",
    title: "Admin Param",
    icon: "menu",
    module: 1,
  },
  admin_questions: {
    component: "Admin",
    url: "admin/questions",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  admin_uploads: {
    component: "Admin",
    url: "admin/uploads",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  admin_users: {
    component: "Admin",
    url: "admin/users",
    title: "Manage Users",
    title: "Users",
    icon: "menu",
    module: 1,
  },
  admin_arrchive: {
    component: "Admin",
    url: "admin/archive/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  admin_approve: {
    component: "Admin",
    url: "admin/verify/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  amil_saheb: {
    component: "AmilSaheb",
    url: "amil-saheb/home",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  amil_saheb_login: {
    component: "AmilSaheb",
    url: "amil-saheb",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  amil_saheb_approval: {
    component: "AmilSaheb",
    url: "amil-saheb/approval/:tanzeem_id/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  amil_saheb_history: {
    component: "AmilSaheb",
    url: "amil-saheb/history/:tanzeem_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  trustee: {
    component: "Trustee",
    url: "trustee/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  trustee_approval: {
    component: "Trustee",
    url: "trustee/approval/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  trustee_sanction: {
    component: "Trustee",
    url: "trustee/sanction/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  trustee_approve: {
    component: "Trustee",
    url: "trustee/approve/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  exce_trustee: {
    component: "ExceTrustee",
    url: "exec-trustee/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  exce_trustee_approval: {
    component: "ExceTrustee",
    url: "exec-trustee/approval/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant: {
    component: "Applicant",
    url: "applicant/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  co: {
    component: "CoOrdinators",
    url: "co/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  not_au: {
    component: "CoOrdinators",
    url: "not-authorised",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  co_arrchive: {
    component: "CoOrdinators",
    url: "co/archive/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  co_approve: {
    component: "CoOrdinators",
    url: "co/verify/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  co_sub_home: {
    component: "CoOrdinators",
    url: "co/home-sub",
    title: "Dashboard",
    icon: "menu",
    module: 1,
  },
  co_documents: {
    component: "CoOrdinators",
    url: "co/documents/:app_id/:track_id",
    title: "Documents",
    icon: "menu",
    module: 1,
  },
  counsellors: {
    component: "Counsellors",
    url: "counsellor/dashboard",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  counsellors_approval: {
    component: "Counsellors",
    url: "counsellor/approval/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  c: {
    component: "Counsellor",
    url: "c/home",
    title: "Dashboard",
    icon: "menu",
    module: 1,
  },
  c_approval: {
    component: "Counsellor",
    url: "c/approval",
    title: "Approval",
    icon: "menu",
    module: 1,
  },
  c_araz: {
    component: "Counsellor",
    url: "c/araz",
    title: "Araz Details",
    icon: "menu",
    module: 1,
  },
  applicant_araiz: {
    component: "Applicant",
    url: "applicant/araiz",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant_araiz_c_araz: {
    component: "Applicant",
    url: "applicant/araiz-fa-check-araz",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant_araiz_personal: {
    component: "Applicant",
    url: "applicant/araiz-fa-personal",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant_araiz_archives: {
    component: "Applicant",
    url: "applicant/archives",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant_araiz_contact: {
    component: "Applicant",
    url: "applicant/contact",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  applicant_araiz_contact: {
    component: "Applicant",
    url: "applicant/viewdownloadapplication/:app_id",
    title: "Home",
    icon: "menu",
    module: 1,
  },
  pdf: {
    component: "PDFViewer",
    url: "pdf/:file",
    title: "PDFViewer",
    icon: "menu",
    module: 1,
  },
  reimbursement_schedule: {
    component: "Reimbursement",
    url: "reimbursement/schedule/:app_id",
    title: "Reimbursement",
    icon: "menu",
    module: 1,
  },
  reimbursement_schedule1: {
    component: "Reimbursement",
    url: "repaymentplan/schedule/:app_id",
    title: "Reimbursement",
    icon: "menu",
    module: 1,
  },
  disbursment_schedule: {
    component: "Disbursment",
    url: "disbursment/schedule/:app_id",
    title: "Disbursment",
    icon: "menu",
    module: 1,
  },
  psynopsis_upload: {
    component: "Psynopsis",
    url: "psynopsis/upload/:app_id",
    title: "Psynopsis",
    icon: "menu",
    module: 1,
  },
  ces_upload: {
    component: "Ces",
    url: "ces/upload/:app_id/:track_id",
    title: "Ces",
    icon: "menu",
    module: 1,
  },
  pledge_upload: {
    component: "Pledge",
    url: "pledge/upload/:app_id/:track_id",
    title: "pledge",
    icon: "menu",
    module: 1,
  },

  searchHeader: {
    component: "SearchHeader",
    url: "header/search",
    title: "pledge",
    icon: "menu",
    module: 1,
  },
  accounts: {
    component: "Accounts",
    url: "accounts/dashboard",
    title: "account",
    icon: "menu",
    module: 1,
  },
  accountsDocUpload: {
    component: "Accounts",
    url: "accounts/file-upload/:appId",
    title: "account",
    icon: "menu",
    module: 1,
  },
};

// modules for grouping.
const modules = {
  0: {
    title: "Dashboard",
    icon: "home",
    isExpendable: true,
  },
};

// component's access to roles.
const rolesConfig = {
  admin: {
    routes: [...Object.values(components)],
  },
  students: {
    routes: [...Object.values(components)],
  },
  common: {
    routes: [
      {
        component: "Login",
        url: "login",
        title: "Login",
        icon: "menu",
        module: 1,
      },
    ],
  },
};

export { modules, rolesConfig };
