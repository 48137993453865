import { lazy } from "react";

const Counsellor = lazy(() => import('../Pages/Counsellor'));
const CoOrdinators =lazy(() => import('../Pages/CoOrdinator'));
const Admin =lazy(() => import('../Pages/Admin'));
const Trustee =lazy(() => import('../Pages/Trustee'));
const Counsellors =lazy(() => import('../Pages/Counsellors'));
const ExceTrustee =lazy(() => import('../Pages/ExcTrustee'));

const AmilSaheb =lazy(() => import('../Pages/Amil'));
const Applicant =lazy(() => import('../Pages/Applicant'));
const Login =lazy(() => import('../Pages/ExcTrustee'));
const PDFViewer =lazy(() => import('../Pages/Leaderboard/PDFViewer'));
const Reimbursement =lazy(() => import('../Pages/Reimbursement'));
const Disbursment =lazy(() => import('../Pages/Disbursment'));
const Psynopsis =lazy(() => import('../Pages/Psynopsis'));
const Ces =lazy(() => import('../Pages/Ces'));
const Pledge =lazy(() => import('../Pages/Pledge'));
const SearchHeader =lazy(() => import('../Pages/SearchList'));
const Accounts =lazy(() => import('../Pages/Accounts'));

export {
  Applicant,
  Counsellor,
  CoOrdinators,
  Admin,
  Trustee,
  ExceTrustee,
  AmilSaheb,
  Login,
  PDFViewer,
  Reimbursement,
  Disbursment,
  Psynopsis,
  Pledge,
  Ces,
  Counsellors,
  SearchHeader,
  Accounts,
};
